<template>
  <v-main>
    <router-view />
    <!-- <v-alert
      style="position: fixed; top: 0; right: 0;"
      type="error"
      class="d-inline-flex mx-10 my-10"
      dense
    >
      test
    </v-alert> -->
    <!-- <dashboard-core-footer /> -->
  </v-main>
</template>

<script>
  // import axios from 'axios'

  export default {
    name: 'DashboardCoreView',

    components: {
      // DashboardCoreFooter: () => import('./Footer'),
    },

    created () {
      // this.initialize()
    },

    methods: {
      initialize () {
        // setInterval(() => {
        //   axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
        //   axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')
        //   axios.post('/g/checkAuth').then((res) => {
        //     if (res.data.status !== 200) {
        //       localStorage.clear()
        //       this.$router.go({ name: 'Login' })
        //     }
        //   }).catch((e) => {
        //     localStorage.clear()
        //     this.$router.go({ name: 'Login' })
        //   })
        // }, 5000)
      },
    },
  }
</script>
